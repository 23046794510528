import React, { Component } from "react";
import { Link } from "gatsby";
import Typical from "react-typical";
import Navbar from "../components/navbar";
// import styles from './NotFound.module.scss';

const SubtitleAnimation = React.memo(
  () => {
    const steps = ["404 Page not found 😱"];
    return <Typical steps={steps} />;
  },
  (props, prevProp) => true
);

class NotFound extends Component {
  render() {
    return (
      <>
        <article>
          <Navbar backNavbar={true} />
          <header>
            <h2>Rishi Goutam</h2>
            <p className="subtitle">
              <SubtitleAnimation />
            </p>
          </header>
        </article>
      </>
    );
  }
}

export default NotFound;
